<template>
  <div>
    <van-popup  v-model:show="show">
      <div class="popup-box u-flex-column u-col-center">
        <div class="content u-flex-column u-col-center">
          <div class="txt">您答对了{{ num }}题</div>
        </div>
        <div class="btn " @click="$router.go(-1)"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['num'],
  data() {
    return {
      show: false
    }
  },
  watch: {

  },
  computed: {},
  methods: {},
  created() { },
  mounted() { }
}
</script>
<style  lang="scss" scoped>
::v-deep .van-popup {
  width: 100% !important;
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 610px;
  background-color: transparent;
}

.popup-box {
  position: relative;

  .content {
    width: 579px;
    height: 853px;
    background-image: url(../../../assets/images/QA/success.png);
    background-size: 100% 100%;

    .txt {
      font-size: 38px;
      font-family: FZLTZHUNHK;
      font-weight: bold;
      color: rgb(0, 0, 0);
      line-height: 84px;
      margin-top: 380px;

    }
  }

  .btn {
    width: 438px;
    height: 116px;
    margin-top: -130px;
    // background-image: url(../../../assets/images/QA/know-btn.png);
    // background-size: 100% 100%;
  }
}
</style>