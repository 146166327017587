<template>
  <div>
    <van-popup  v-model:show="show">
      <div class="popup-box u-flex-column u-col-center">
        <div class="content u-flex-column u-col-center">
          <div class="txt">正确答案：选{{ answer }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['answer'],
  data() {
    return {
      show: false
    }
  },
  watch: {
    show(val) {
      if (val) {
        setTimeout(() => {
          this.show = false
        }, 2000)
      }
    }
  },
  computed: {},
  methods: {},
  created() { },
  mounted() { }
}
</script>
<style  lang="scss" scoped>
 ::v-deep .van-popup {
  width: 100% !important;
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 610px;
  background-color: transparent !important;
}

.popup-box {
  position: relative;

  .content {
    width: 579px;
    height: 593px;
    background-image: url(../../../assets/images/QA/error.png);
    background-size: 100% 100%;

    .txt {
      font-size: 42px;
      font-family: FZLTZHK;
      color: rgb(0, 0, 0);
      line-height: 84px;
      margin-top: 110px;

    }
  }
}
</style>