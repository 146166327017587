<template>
  <div class="wrapper u-flex-column u-col-center">
    <div class="u-flex" style="width: 100%;">
      <!-- <img class="logo" src="@/assets/images/QA/logo@2x.png" alt=""> -->
    </div>
    <img class="title" src="@/assets/images/QA/title1.png" alt="">
    <div class="subject-box">
      <template v-if="list.length > 0">
        <div class="content">
          <div class="head u-flex u-row-between u-m-t-300">
            <div class="num">题目:<span>{{ current < 10 ? ('0' + current) : current }}</span>/{{ list.length }}
            </div>
            <!-- <div class="lb" v-if="current != list.length">选择您的答案进入下一题</div>
            <div class="lb" v-else>选择您的答案并确认</div> -->
          </div>
          <div class="icon1" v-show="list[current - 1].multiple == 2">多选题</div>
          <div class="icon2" v-show="list[current - 1].multiple == 1">单选题</div>
          <div class="subject">
            {{ list[current - 1].subject }}
          </div>
          <div class="list">
            <!-- <div class="item" :class="[index == 0 && 'error', index == 1 && 'correct', index == 2 && 'select']"
              v-for="(item, index) in list[current].list" @click="selectClick(list[current].list, item)" :key="index"> -->
            <div class="item"
              :class="[item.choice == true && 'select', list[current - 1].submitData != '' && (list[current - 1].answer.indexOf(item.name) != -1 && 'correct'), list[current - 1].submitData != '' && item.choice && list[current - 1].submitData.indexOf(item.name) != -1 && 'error']"
              v-for="(item, index) in list[current - 1].list"
              @click="list[current - 1].submitData == '' && selectClick(list[current - 1], item)" :key="index">
              {{ item.name }}、{{ item.optionStr }}
            </div>
          </div>
        </div>
      </template>
      <div class="bg">
        <div class="t"></div>
        <div class="c"></div>
        <div class="b"></div>
      </div>
    </div>
    <template v-if="list.length > 0">
      
      <div v-show="list[current - 1].submitData != ''&&current!=5" @click="current++" class="next-btn"></div>
      <div v-show="list[current - 1].submitData == ''" @click="determineClick(list[current - 1])" class="determine-btn">
      </div>
    </template>

    <!-- <img class="yun" src="@/assets/images/QA/yun1.png" alt=""> -->

    <!-- 答题错误 -->
    <v-error ref="error" :answer="answer" />

    <!-- 答题正确 -->
    <v-correct ref="correct" />

    <!-- 答题失败 -->
    <v-fail ref="Fail" :num="num" />


    <!-- 答题成功 -->
    <v-success ref="Success" :num="num" />

    <!-- 加载中 -->
    <loading v-show="pageLadingShow"></loading>
    <!-- 请求失败 -->
    <noNetwork v-if="err" @cb="cb"></noNetwork>
  </div>
</template>

<script>
import error from './error.vue'
import correct from './correct.vue'
import fail from './fail.vue'
import success from './success.vue'
import noNetwork from '@/components/no_network/index.vue'
import loading from '@/components/loading/index.vue'
// import { postQuestionAnswerProblemList, postQuestionAnswerSubmit } from '@/api'
export default {
  components: {
    'v-error': error,
    'v-correct': correct,
    'v-fail': fail,
    'v-success': success,
    noNetwork,
    loading
  },
  props: {},
  data() {
    return {
      list: [],
      current: 1,
      answer: {},
      num: 0,
      err:false,
      pageLadingShow:true
    }
  },
  watch: {},
  computed: {},
  methods: {
    cb(){
      this.err = false
      this.postQuestionAnswerProblemList()
    },
    postQuestionAnswerProblemList() {
      let that = this
      let item =  this.$api.getProblemList().then(res=>{
        console.log(res)
        let list = res.data
        for (let item of list) {
          for (let im of item.list) {
            im.choice = false
          }
          item.submitData = ''
        }
        that.list = list
        console.log(that.list)

      }).catch(e=>{
        if(e.data.code != 400){
          this.err = true
        }
          
      }).finally(()=>{
        this.pageLadingShow = false
      })
      // postQuestionAnswerProblemList({}).then(res => {
      //   let list = res.data.data
      //   for (let item of list) {
      //     for (let im of item.list) {
      //       im.choice = false
      //     }
      //     item.submitData = ''
      //   }
      //   this.list = list
      // })
    },
    selectClick(list, item) {
      console.log(list)
      if (list.multiple == 1) {
        console.log(list);
        for (let im of list.list) {
          im.choice = false
        }
        item.choice = true
      } else {
        item.choice = !item.choice
      }

    },
    //点击确定
    determineClick(item) {

      let submitData = []
      for (var im of item.list) {
        if (im.choice) {
          submitData.push(im.name)
        }
      }
      item.submitData = submitData.join()
      if (item.submitData == '') {
        return this.$toast('请选择答案')
      } else {
        this.answer = item.answer
        if (item.submitData == item.answer) {
          this.$refs.correct.show = true
        } else {
          this.$refs.error.show = true
        }
        if (this.current == this.list.length) {
          setTimeout(() => {
            // this.$toast.loading({
            //   message: '',
            //   forbidClick: true,
            //   duration: 0
            // })
            // this.pageLadingShow= true
            let answerJson = []
            for (let im of this.list) {
              answerJson.push({
                id: im.id,
                answer: im.submitData
              })
            }
            let that = this
            let submit = this.$api.submit({
              answerJson: JSON.stringify(answerJson)
            }).then(res=>{
              let num = res.data
              that.num = num
              if (num >= 3) {
                // that.$refs.Success.num = num
                that.$refs.Success.show = true
              } else {
                // that.$refs.Fail.num = num
                that.$refs.Fail.show = true
              }

            }).finally(() => {
              // this.pageLadingShow = false
              
              // that.$toast.clear()
            })
            // postQuestionAnswerSubmit({
            //   answerJson: JSON.stringify(answerJson)
            // }).then(res => {
            //   let num = res.data.data
            //   this.num = num
            //   if (num >= 3) {
            //     this.$refs.Success.num = num
            //     this.$refs.Success.show = true
            //   } else {
            //     this.$refs.Fail.num = num
            //     this.$refs.Fail.show = true
            //   }
            // }).finally(() => {
            //   this.$toast.clear()
            // })
          }, 2000)
        }

      }
    }
  },
  created() {
    this.postQuestionAnswerProblemList()
  },
  mounted() { }
}
</script>
<style  lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-image: url(../../../assets/images/QA/qaLoadingBg.png);
  background-size: 100% 100%;
  overflow: hidden;
  padding-bottom: 60px;
  box-sizing: border-box;

  .icon1 {
    width: 130px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: linear-gradient(-3deg, #F2284D, #F95B89);
    border-radius: 5px;
    font-size: 29px;
    color: #fff;
    margin-top: 10px;
  }

  .icon2 {
    width: 130px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: linear-gradient(-3deg, #FF8E38, #F9B63E);
    border-radius: 5px;
    font-size: 29px;
    color: #fff;
    margin-top: 10px;
  }

  .logo {
    width: 316px;
    height: 40px;
    margin-left: 20px;
    margin-top: 20px;
  }

  .title {
    width: 501px;
    height: 232px;
    margin-top: 20px;
    margin-left: 240px;
  }

  .subject-box {
    position: relative;
    width: 678px;
    margin-top: -200px;
    padding-bottom: 40px;
    box-sizing: border-box;

    .content {
      position: relative;
      z-index: 1;
      width: 100%;
      padding: 0 43px;
      box-sizing: border-box;

      .num {
        font-size: 33px;
        font-family: FZLTZHK;
        font-weight: bold;
        color: #000;
        line-height: 44px;
        margin-top: 30px;

        span {
          color: #ff9808 !important;
        }
      }

      .lb {
        font-size: 25px;
        font-family: FZLTZHUNHK;
        font-weight: normal;
        color: #808080;
        line-height: 44px;
      }

      .subject {
        font-size: 33px;
        font-family: FZLTZHUNHK;
        font-weight: bold;
        color: #4C4B4C;
        line-height: 58px;
        margin-top: 10px;
      }

      .list {
        width: 100%;
        margin-top: 45px;

        .item {
          width: 100%;
          padding: 30px 40px 30px 20px;
          background: #ffebd6;
          border-radius: 35px;
          box-sizing: border-box;
          font-size: 30px;
          font-family: FZLTZHK;
          font-weight: bold;
          color: #916a59;
          line-height: 58px;
          margin-bottom: 20px;
        }

        .error {
          position: relative;
          background-color: #ff5963 !important;
          // background: #FF5963;
          // color: #fff;

          &::after {
            content: '';
            position: absolute;
            right: 10px;
            top: calc(50% - 20px);
            width: 40px;
            height: 40px;
            background-image: url(../../../assets/images/QA/icon-error.png);
            background-size: 100% 100%;
          }
        }

        .select {
          position: relative;
          background: #f9c325;
          color: #fff;

          // &::after {
          //   content: '';
          //   position: absolute;
          //   right: -.2px;
          //   top: calc(50% - .2px);
          //   width: .4px;
          //   height: .4px;
          //   background-image: url(../../../assets/images/QA/icon-select.png);
          //   background-size: 100% 100%;
          // }
        }

        .correct {
          position: relative;
          background-color: #26e39a !important;
          color: #fff !important;
          // background: #25E399;
          // color: #fff;

          &::after {
            content: '';
            position: absolute;
            right: 10px;
            top: calc(50% - 20px);
            width: 40px;
            height: 40px;
            background-image: url(../../../assets/images/QA/icon-correct.png);
            background-size: 100% 100%;
          }
        }
      }
    }

    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;



      .t {
        width: 696px;
        height: 419px;
        background-image: url(../../../assets/images/QA/subject-box_01.png);
        background-size: 100% 100%;
      }

      .c {
        width: 696px;
        height: calc(100% - 514px);
        background-image: url(../../../assets/images/QA/subject-box_02.png);
        background-size: 100% auto;
        margin-top: -10px;
      }

      .b {
        width: 696px;
        height: 115px;
        background-image: url(../../../assets/images/QA/subject-box_03.png);
        background-size: 100% 100%;
        margin-top: -20px;
      }
    }
  }

  .next-btn {
    width: 437px;
    height: 115px;
    background-image: url(../../../assets/images/QA/next-btn.png);
    background-size: 100% 100%;
    margin-top: 40px;
  }

  .determine-btn {
    width: 437px;
    height: 115px;
    background-image: url(../../../assets/images/QA/determine-btn.png);
    background-size: 100% 100%;
    margin-top: 40px;
  }

  .yun {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 115px;
    pointer-events: none;
  }
}
</style>