<template>
  <div>
    <van-popup v-model:show="show">
      <div class="popup-box u-flex-column u-col-center">
        <div class="content u-flex-column u-col-center">
          <div class="continue" @click="show = false"></div>
        </div>
      </div>
    </van-popup>
  </div>

</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      show: false
    }
  },
  watch: {
  },
  computed: {},
  methods: {},
  created() { },
  mounted() { }
}
</script>
<style  lang="scss" scoped>
::v-deep .van-popup {
  width: 100% !important;
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 6.1px;
  background-color: transparent;
}

.popup-box {
  position: relative;

  .content {
    width: 704px;
    height: 678px;
    background-image: url(../../../assets/images/QA/correct.png);
    background-size: 100% 100%;
    .continue{
      width: 430px;
      height: 80px;
      margin-top: 560px;
    }

    .txt {
      font-size: 42px;
      font-family: FZLTZHK;
      color: rgba(254, 187, 67, 1);
      line-height: 84px;
      margin-top: 260px;

    }
  }
}
</style>